@import '../../../../scss/theme-bootstrap';

.product-quickshop-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
}

.product-quickshop {
  position: relative;
  line-height: 1;
  z-index: 1;
  background: $color-white;
  @include breakpoint($medium-up) {
    padding: 0;
    &:after {
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
      z-index: -1;
      border: 10px solid $color-white;
      box-shadow: 0 0 0 1px $color-border;
    }
    .qs-overlay-cbox & {
      border: 0;
      padding: 10px;
      &:after {
        display: none;
      }
    }
    .product {
      &__subline {
        margin-top: 5px;
      }
    }
  }
}

.product-quickshop__container {
  @include breakpoint($medium-up) {
    @include pie-clearfix;
    background: $color-mpp-light-gray;
    .product-quickshop-wrapper & {
      .product-grid--theme-white & {
        background-color: $color-white;
      }
      .product-grid--theme-light-sand & {
        background-color: $color-light-sand;
      }
      .product-grid--theme-sand & {
        background-color: $color-sand;
      }
      .product-grid--theme-light-green & {
        background-color: $color-light-green;
      }
      .product-grid--theme-light-blue & {
        background-color: $color-light-blue;
      }
      .product-grid--theme-black & {
        @include font-smoothing(true);
        background-color: $color-black;
        color: $color-white;
        a,
        a.selectBox,
        select {
          color: $color-white;
        }
      }
    }
  }
}

.product-quickshop__media {
  position: relative;
  @include breakpoint($medium-up) {
    float: left;
    width: 46.72897%;
    margin-right: 5.14019%;
    overflow: hidden;
  }
}

.product-quickshop__content {
  padding: 0 20px 20px 20px;
  @include breakpoint($medium-up) {
    float: left;
    width: 42.99065%;
    margin-right: 5.14019%;
    max-width: 410px;
    padding: 35px 0;
  }
}

.product-quickshop__image {
  text-align: center;
  position: relative;
}

.product-quickshop__header {
  text-align: center;
  padding: 0 5% 15px 5%;
  margin: 0 0 15px 0;
  border-bottom: 1px solid $color-border;
  @include breakpoint($medium-up) {
    text-align: left;
    padding: 0;
    margin: 0;
    border: 0;
  }
}

.product-quickshop__price-wrapper {
  @include breakpoint($medium-up) {
    @include pie-clearfix;
    border-top: 1px solid #e2e1e1;
    border-bottom: 1px solid #e2e1e1;
    height: 45px;
    line-height: 43px;
    margin: 15px 0;
  }
}

.product-quickshop__price {
  @include breakpoint($medium-up) {
    float: left;
  }
}

.product-quickshop__rating {
  @include breakpoint($medium-up) {
    float: right;
  }
}

.product-quickshop__summary {
  font-size: $base-mobile-font-size;
  line-height: $base-mobile-line-height;
  margin: 10px 0;
  @include breakpoint($medium-up) {
    font-size: $base-font-size;
    line-height: $base-line-height;
    border-bottom: 1px solid #e2e1e1;
    margin: 15px 0;
    padding-bottom: 20px;
  }
  p {
    margin: 0 0 10px 0;
  }
  &-link {
    @include link--secondary;
  }
}

.product-quickshop__sku {
  position: relative;
  .product-inventory-status {
    position: absolute;
    top: 0;
    right: 20px;
    margin: 0;
    @include breakpoint($medium-up) {
      right: 0;
    }
  }
}

.product-quickshop__cta {
  text-align: center;
  margin: 0 0 10px 0;
  .button {
    width: 100%;
  }
  @include breakpoint($medium-up) {
    text-align: left;
    margin: 0 4px 5px 0;
    display: inline-block;
    .button {
      width: auto;
    }
  }
}

.product-quickshop__social {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
    position: absolute;
    bottom: 35px;
    right: 10px;
  }
}

.product-quickshop__offer {
  font-size: 14px;
  margin: 12px 0 15px 0;
  a {
    color: $color-text;
    &:hover {
      text-decoration: none;
    }
  }
}

.product-quickshop__close {
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 14px;
  color: $color-black;
  text-decoration: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  @include breakpoint($medium-up) {
    font-size: 18px;
  }
  .qs-overlay-cbox & {
    display: none;
  }
}
